<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="5">
              <CSelect
                :placeholder="$t('search_a') + $t('category')"
                :options="productCategoriesOptions"
                :value.sync="searchData.product_category_id"
              />
            </CCol>
            <CCol col="12" sm="5">
              <CInput
                :placeholder="$t('search_a') + $t('name')"
                v-model="searchData.name"
                v-on:keyup.enter="getProducts()"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getProducts()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createProductModal = { modal: true }">{{ $t('create_product') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="category" slot-scope="props">
              {{ props.row.category ? props.row.category.name_en : '' }}
            </font>
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => productImagesModal = { data: props.row, modal: true }">{{ $t('images') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => optionsModal = { data: props.row, modal: true }">{{ $t('options') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => specificationsModalModal = { data: props.row, modal: true }">{{ $t('specifications') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editProductModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteProductModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <OptionsModal :data="optionsModal.data" :show="optionsModal.modal" @showUpdate="show => optionsModal.modal = show" />

      <SpecificationsModal :data="specificationsModalModal.data" :show="specificationsModalModal.modal" @showUpdate="show => specificationsModalModal.modal = show" />

      <CreateProductModal :data="createProductModal.data" :show="createProductModal.modal" @showUpdate="show => createProductModal.modal = show" />

      <EditProductModal :data="editProductModal.data" :show="editProductModal.modal" @showUpdate="show => editProductModal.modal = show" />

      <DeleteProductModal :data="deleteProductModal.data" :show="deleteProductModal.modal" @showUpdate="show => deleteProductModal.modal = show" />

      <ProductImagesModal :data="productImagesModal.data" :show="productImagesModal.modal" @showUpdate="show => productImagesModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import OptionsModal from './modal/OptionsModal'
import SpecificationsModal from './modal/SpecificationsModal'
import CreateProductModal from './modal/CreateProductModal'
import EditProductModal from './modal/EditProductModal'
import DeleteProductModal from './modal/DeleteProductModal'
import ProductImagesModal from './modal/ProductImagesModal'

export default {
  name: 'products',
  components: {
    OptionsModal,
    SpecificationsModal,
    CreateProductModal,
    EditProductModal,
    DeleteProductModal,
    ProductImagesModal,
  },
  data () {
    return {
      columns: [ 'category', 'name_en', 'name_zh', 'price', 'view_count', 'order_count', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          category: this.$t('category'),
          name_en: this.$t('name') + ' (EN)',
          name_zh: this.$t('name') + ' (ZH)',
          price: this.$t('price'),
          view_count: this.$t('view_count'),
          order_count: this.$t('order_count'),
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'category', 'name_en', 'name_zh', 'price', 'view_count', 'order_count', 'enabled', 'created_at' ],
        filterable: [ 'category', 'name_en', 'name_zh' ]
      },
      productCategoriesOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('category') + '...' }
      ],
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { name: '', product_category_id: null },
      optionsModal: { data: {}, modal: false },
      specificationsModalModal: { data: {}, modal: false },
      createProductModal: { data: {}, modal: false },
      editProductModal: { data: {}, modal: false },
      deleteProductModal: { data: {}, modal: false },
      productImagesModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getProductCategories()
  },
  methods: {
    getProductCategories () {
      const loader = this.$loading.show()
      this.$store.dispatch('getProductCategories', { enabled: 1 }).then(res => {
        this.productCategoriesOptions = [{ value: null, label: this.$t('please_select_a') + this.$t('category') + '...' }]
        for (const i in res) {
          this.productCategoriesOptions.push({ value: res[i].id, label: res[i].name_en, disabled: res[i].sub_categories.length > 0 })
          for (const x in res[i].sub_categories) {
            this.productCategoriesOptions.push({ value: res[i].sub_categories[x].id, label: '- ' + res[i].sub_categories[x].name_en })
          }
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getProducts () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getProducts', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
