<template>
  <CModal
    :title="$t('edit_option')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('type')"
          :options="typeOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.type"
          :isValid="valid_formData_type"
          :disabled="formData.parent"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name') + ' (EN)'"
          :placeholder="$t('name') + ' (EN)'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name_en"
          :isValid="valid_formData_name_en"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('message') + ' (EN)'"
          :placeholder="$t('message') + ' (EN)'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.message_en"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name') + ' (ZH)'"
          :placeholder="$t('name') + ' (ZH)'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name_zh"
          :isValid="valid_formData_name_zh"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('message') + ' (ZH)'"
          :placeholder="$t('message') + ' (ZH)'"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.message_zh"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('price')"
          :placeholder="$t('price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          step="0.01"
          v-model="formData.price"
          :isValid="valid_formData_price"
        />
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditOptionModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      typeOptions: [
        { value: 1, label: this.$t('text') },
        { value: 2, label: this.$t('color') }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showProductOption()
      }
    }
  },
  computed: {
    valid_formData_type () {
      return this.formData.type ? true : false
    },
    valid_formData_name_en () {
      return this.formData.name_en ? true : false
    },
    valid_formData_name_zh () {
      return this.formData.name_zh ? true : false
    },
    valid_formData_price () {
      return /^[0-9]+(.[0-9]+)?$/.test(this.formData.price) ? true : false
    },
    valid_submit () {
      return this.valid_formData_type && this.valid_formData_name_en && this.valid_formData_name_zh && this.valid_formData_price
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showProductOption () {
      const loader = this.$loading.show()
      this.$store.dispatch('showProductOption', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateProductOption', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
