<template>
  <CModal
    :title="$t('sub_options')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('parent_option')"
          :placeholder="$t('parent_option')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="modal.data.name_en"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="showProductOption()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="8" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="dark" block @click="() => editOptionSortModal = { data: { sortData: $_.sortBy(table.data, 'sort') }, modal: true }">{{ $t('sort') }}</CButton>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="() => createOptionModal = { data: { product_id: modal.data.product_id, parent: modal.data.id, type: modal.data.type }, modal: true }">{{ $t('create_category') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <font slot="type" slot-scope="props">
        {{ $_.find(typeOptions, option => { return option.value === props.row.type }).label }}
      </font>
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editOptionModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
          <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteOptionModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <div slot="footer-wrapper"></div>

    <CreateOptionModal :data="createOptionModal.data" :show="createOptionModal.modal" @showUpdate="show => createOptionModal.modal = show" />

    <EditOptionModal :data="editOptionModal.data" :show="editOptionModal.modal" @showUpdate="show => editOptionModal.modal = show" />

    <DeleteOptionModal :data="deleteOptionModal.data" :show="deleteOptionModal.modal" @showUpdate="show => deleteOptionModal.modal = show" />

    <EditOptionSortModal :data="editOptionSortModal.data" :show="editOptionSortModal.modal" @showUpdate="show => editOptionSortModal.modal = show" />

  </CModal>
</template>

<script>
import CreateOptionModal from '../modal/CreateOptionModal'
import EditOptionModal from '../modal/EditOptionModal'
import DeleteOptionModal from '../modal/DeleteOptionModal'
import EditOptionSortModal from '../modal/EditOptionSortModal'

export default {
  name: 'SubOptionsModal',
  components: {
    CreateOptionModal,
    EditOptionModal,
    DeleteOptionModal,
    EditOptionSortModal
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: [ 'type', 'name_en', 'name_zh', 'price', 'sort', 'action' ],
        data: [],
        options: {
          headings: {
            type: this.$t('type'),
            name_en: this.$t('name') + ' (EN)',
            name_zh: this.$t('name') + ' (ZH)',
            price: this.$t('price'),
            sort: this.$t('sort'),
            action: this.$t('action')
          },
          orderBy: { ascending: true, column: 'sort' },
          sortable: [ 'type', 'name_en', 'name_zh', 'price', 'sort' ],
          filterable: [ 'name_en', 'name_zh' ]
        },
      },
      typeOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('type') + '...' },
        { value: 1, label: this.$t('text') },
        { value: 2, label: this.$t('color') }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      subOptionsModal: { data: {}, modal: false },
      createOptionModal: { data: {}, modal: false },
      editOptionModal: { data: {}, modal: false },
      deleteOptionModal: { data: {}, modal: false },
      editOptionSortModal: { data: {}, modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.searchData = { id: this.modal.data.id }
        this.showProductOption()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    showProductOption () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('showProductOption', this.$_.clone(this.searchData)).then(res => {
        this.modal.data = res[0]
        this.table.data = res[0].sub_options
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res[0].sub_options.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  },
  beforeDestroy: function () {
  },
}
</script>
