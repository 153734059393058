<template>
  <CModal
    :title="$t('edit_product')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CTabs :fill="true" variant="tabs" :fade="false" :active-tab="0" addNavWrapperClasses="pb-4">
      <CTab title="EN">
        <CRow>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('category')"
              :options="productCategoriesOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.product_category_id"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('tag')"
              :options="productTagsOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.product_tag_id"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (EN)'"
              :placeholder="$t('name') + ' (EN)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.name_en"
              :isValid="valid_formData_name_en"
            />
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('introduction') + ' (EN)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('introduction') + ' (EN)'"
                  :class="['form-control', valid_formData_introduction_en ? 'is-valid' : 'is-invalid']"
                  v-model="formData.introduction_en"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('description') + ' (EN)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('description') + ' (EN)'"
                  :class="['form-control', valid_formData_description_en ? 'is-valid' : 'is-invalid']"
                  v-model="formData.description_en"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('price')"
              :placeholder="$t('price')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              step="0.01"
              v-model="formData.price"
              :isValid="valid_formData_price"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('weight')"
              :placeholder="$t('weight')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              step="0.01"
              v-model="formData.weight"
              :isValid="valid_formData_weight"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('inventory')"
              :placeholder="$t('inventory')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              v-model="formData.inventory"
              :isValid="valid_formData_inventory"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('purchase_limit')"
              :placeholder="$t('purchase_limit')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              v-model="formData.purchase_limit"
              :isValid="valid_formData_purchase_limit"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CSelect
              :label="$t('enable')"
              :options="enabledOptions"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              :value.sync="formData.enabled"
              :isValid="valid_formData_enabled"
            />
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">
                {{ $t('launch_time') }}
              </label>
              <div class="col-sm-9">
                <date-picker
                  v-model="formData.launched_at"
                  :shortcuts="false"
                  :editable="false"
                  :not-before="$moment()"
                  width="100%"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  :input-class="['form-control', 'bg-white', valid_formData_launched_at ? 'is-valid' : 'is-invalid']"
                />
              </div>
            </div>
          </CCol>
        </CRow>
      </CTab>
      <CTab title="ZH">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (ZH)'"
              :placeholder="$t('name') + ' (ZH)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.name_zh"
              :isValid="valid_formData_name_zh"
            />
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('introduction') + ' (ZH)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('introduction') + ' (ZH)'"
                  :class="['form-control', valid_formData_introduction_zh ? 'is-valid' : 'is-invalid']"
                  v-model="formData.introduction_zh"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
          <CCol col="12" sm="12">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-12">
                {{ $t('description') + ' (ZH)' }}
              </label>
              <div class="col-sm-12">
                <textarea-autosize
                  :placeholder="$t('description') + ' (ZH)'"
                  :class="['form-control', valid_formData_description_zh ? 'is-valid' : 'is-invalid']"
                  v-model="formData.description_zh"
                  :min-height="100"
                  :max-height="350"
                />
              </div>
            </div>
          </CCol>
        </CRow>
      </CTab>
    </CTabs>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditProductModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      productCategoriesOptions: [
        { value: null, label: this.$t('none') }
      ],
      productTagsOptions: [
        { value: null, label: this.$t('none') }
      ],
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.getProductCategories()
        this.getProductTags()
        this.showProduct()
      }
    }
  },
  computed: {
    valid_formData_name_en () {
      return this.formData.name_en ? true : false
    },
    valid_formData_introduction_en () {
      return this.formData.introduction_en ? true : false
    },
    valid_formData_description_en () {
      return this.formData.description_en ? true : false
    },
    valid_formData_name_zh () {
      return this.formData.name_zh ? true : false
    },
    valid_formData_introduction_zh () {
      return this.formData.introduction_zh ? true : false
    },
    valid_formData_description_zh () {
      return this.formData.description_zh ? true : false
    },
    valid_formData_price () {
      return /^[0-9]+(.[0-9]+)?$/.test(this.formData.price) ? true : false
    },
    valid_formData_weight () {
      return /^[0-9]+(.[0-9]+)?$/.test(this.formData.weight) ? true : false
    },
    valid_formData_inventory () {
      return /^[0-9]+$/.test(this.formData.inventory) ? true : false
    },
    valid_formData_purchase_limit () {
      return !this.formData.purchase_limit || /^[1-9][0-9]*$/.test(this.formData.purchase_limit) ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_formData_launched_at () {
      return !this.formData.launched_at || this.$moment(this.formData.launched_at).isValid() ? true : false
    },
    valid_submit () {
      return this.valid_formData_name_en && this.valid_formData_introduction_en && this.valid_formData_description_en && this.valid_formData_name_zh && this.valid_formData_introduction_zh && this.valid_formData_description_zh && this.valid_formData_price && this.valid_formData_weight && this.valid_formData_inventory && this.valid_formData_enabled
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showProduct () {
      const loader = this.$loading.show()
      this.$store.dispatch('showProduct', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getProductCategories () {
      const loader = this.$loading.show()
      this.$store.dispatch('getProductCategories', { enabled: 1 }).then(res => {
        this.productCategoriesOptions = [{ value: null, label: this.$t('none') }]
        for (const i in res) {
          this.productCategoriesOptions.push({ value: res[i].id, label: res[i].name_en, disabled: res[i].sub_categories.length > 0 })
          for (const x in res[i].sub_categories) {
            this.productCategoriesOptions.push({ value: res[i].sub_categories[x].id, label: '- ' + res[i].sub_categories[x].name_en })
          }
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    getProductTags () {
      const loader = this.$loading.show()
      this.$store.dispatch('getProductTags', { enabled: 1 }).then(res => {
        this.productTagsOptions = [{ value: null, label: this.$t('none') }]
        for (const i in res) {
          this.productTagsOptions.push({ value: res[i].id, label: res[i].name_en })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateProduct', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
