<template>
  <CModal
    :title="$t('create_specification')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CTabs :fill="true" variant="tabs" :fade="false" :active-tab="0" addNavWrapperClasses="pb-4">
      <CTab title="EN">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (EN)'"
              :placeholder="$t('name') + ' (EN)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.title_en"
              :isValid="valid_formData_title_en"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('content') + ' (EN)'"
              :placeholder="$t('content') + ' (EN)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.content_en"
              :isValid="valid_formData_content_en"
            />
          </CCol>
        </CRow>
      </CTab>
      <CTab title="ZH">
        <CRow>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('name') + ' (ZH)'"
              :placeholder="$t('name') + ' (ZH)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.title_zh"
              :isValid="valid_formData_title_zh"
            />
          </CCol>
          <CCol col="12" sm="12">
            <CInput
              :label="$t('content') + ' (ZH)'"
              :placeholder="$t('content') + ' (ZH)'"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              v-model="formData.content_zh"
              :isValid="valid_formData_content_zh"
            />
          </CCol>
        </CRow>
      </CTab>
    </CTabs>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'CreateSpecificationModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { product_id: this.modal.data.product_id, title_en: '', content_en: '', title_zh: '', content_zh: '' }
      }
    }
  },
  computed: {
    valid_formData_title_en () {
      return this.formData.title_en ? true : false
    },
    valid_formData_content_en () {
      return this.formData.content_en ? true : false
    },
    valid_formData_title_zh () {
      return this.formData.title_zh ? true : false
    },
    valid_formData_content_zh () {
      return this.formData.content_zh ? true : false
    },
    valid_submit () {
      return this.valid_formData_title_en && this.valid_formData_content_en && this.valid_formData_title_zh && this.valid_formData_content_zh
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('storeProductSpecification', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('created_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
