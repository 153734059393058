<template>
  <CModal
    :title="$t('specifications')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('product')"
          :placeholder="$t('product')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="modal.data.name_en"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="getProductSpecifications()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="8" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="dark" block @click="() => editSpecificationSortModal = { data: { sortData: $_.sortBy(table.data, 'sort') }, modal: true }">{{ $t('sort') }}</CButton>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="() => createSpecificationModal = { data: { product_id: modal.data.id }, modal: true }">{{ $t('create_category') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editSpecificationModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
          <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteSpecificationModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <div slot="footer-wrapper"></div>

    <CreateSpecificationModal :data="createSpecificationModal.data" :show="createSpecificationModal.modal" @showUpdate="show => createSpecificationModal.modal = show" />

    <EditSpecificationModal :data="editSpecificationModal.data" :show="editSpecificationModal.modal" @showUpdate="show => editSpecificationModal.modal = show" />

    <DeleteSpecificationModal :data="deleteSpecificationModal.data" :show="deleteSpecificationModal.modal" @showUpdate="show => deleteSpecificationModal.modal = show" />

    <EditSpecificationSortModal :data="editSpecificationSortModal.data" :show="editSpecificationSortModal.modal" @showUpdate="show => editSpecificationSortModal.modal = show" />

  </CModal>
</template>

<script>
import CreateSpecificationModal from '../modal/CreateSpecificationModal'
import EditSpecificationModal from '../modal/EditSpecificationModal'
import DeleteSpecificationModal from '../modal/DeleteSpecificationModal'
import EditSpecificationSortModal from '../modal/EditSpecificationSortModal'

export default {
  name: 'SpecificationsModal',
  components: {
    CreateSpecificationModal,
    EditSpecificationModal,
    DeleteSpecificationModal,
    EditSpecificationSortModal
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: [ 'title_en', 'title_zh', 'sort', 'action' ],
        data: [],
        options: {
          headings: {
            title_en: this.$t('title') + ' (EN)',
            title_zh: this.$t('title') + ' (ZH)',
            sort: this.$t('sort'),
            action: this.$t('action')
          },
          orderBy: { ascending: true, column: 'sort' },
          sortable: [ 'title_en', 'title_zh', 'sort' ],
          filterable: [ 'title_en', 'title_zh' ]
        },
      },
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      createSpecificationModal: { data: {}, modal: false },
      editSpecificationModal: { data: {}, modal: false },
      deleteSpecificationModal: { data: {}, modal: false },
      editSpecificationSortModal: { data: {}, modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.searchData = { product_id: this.modal.data.id }
        this.getProductSpecifications()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getProductSpecifications () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getProductSpecifications', this.$_.clone(this.searchData)).then(res => {
        this.table.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  },
  beforeDestroy: function () {
  },
}
</script>
