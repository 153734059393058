<template>
  <CModal
    :title="$t('product_images')"
    size="xl"
    color="info"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value="formData.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="$refs.uploadImage.click()">{{ $t('upload_image') }}</CButton>
        <input type="file" multiple ref="uploadImage" style="position: absolute;clip: rect(0 0 0 0);" accept="image/png, image/jpeg, image/jpg" @change="multipleImagesUpload">
      </CCol>
    </CRow>
    <hr>
    <p class="text-muted pl-2">{{ $t('drag_image_msg') }}</p>
    <CRow>
      <CCol col="12" sm="12">
        <draggable
          class="list-group"
          tag="ul"
          v-model="formData.images"
          v-bind="dragOptions"
        >
          <li
            class="list-group-item"
            v-for="(e, index) in formData.images"
            :key="index"
          >
            <CRow>
              <CCol col="12" sm="6">
                <CImg
                  :src="e"
                  shape="rounded"
                  fluid
                  class="list-group-img"
                />
              </CCol>
              <CCol col="12" sm="6" class="d-flex justify-content-end align-items-end">
                <CButton color="danger" v-bind="{ variant: 'outline', shape: 'pill' }" @click="formData.images.splice(index, 1)"><CIcon name="cil-trash" /></CButton>
              </CCol>
            </CRow>
          </li>
        </draggable>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>

    <CropperModal :img="cropperModalData.img" :show="cropperModalData.modal" @showUpdate="show => cropperModalData.modal = show" @cropImage="cropImage" />
  </CModal>
</template>

<style>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group-item {
  cursor: move;
}
.list-group-img {
  max-width: 100%;
  max-height: 200px;
}
</style>

<script>
import draggable from 'vuedraggable'
import CropperModal from '@/views/cropper/CropperModal'

export default {
  name: 'ProductImagesModal',
  components: {
    draggable,
    CropperModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      productCategoriesOptions: [
        { value: null, label: this.$t('none') }
      ],
      productTagsOptions: [
        { value: null, label: this.$t('none') }
      ],
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      formData: {},
      cropperModalData: { img: '', modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.formData = { product_id: this.modal.data.id, name: this.modal.data.name_en, images: [] }
        this.getProductImages()
      }
    }
  },
  computed: {
    valid_formData_product_id () {
      return this.formData.product_id ? true : false
    },
    valid_formData_images () {
      return this.$_.isArray(this.formData.images) && this.formData.images.length > 0 ? true : false
    },
    valid_submit () {
      return this.valid_formData_product_id && this.valid_formData_images
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getProductImages () {
      const loader = this.$loading.show()
      this.$store.dispatch('getProductImages', this.$_.clone(this.formData)).then(res => {
        this.formData.images = this.$_.pluck(this.$_.sortBy(res[0], 'sort'), 'url')
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateProductImages', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    multipleImagesUpload (e) {
      const files = e.target.files
      if (!files || !files.length > 0) {
        //
      } else {
        const formData = new FormData()
        for (const i in files) {
          if (!(files[i].name && files[i].type && files[i].size)) continue
          if (!/\.(png|jpeg|jpg)$/.test(files[i].name.toLowerCase()) || (files[i].type !== 'image/png' && files[i].type !== 'image/jpeg' && files[i].type !== 'image/jpg')) {
            this.$swal('Warning', '上傳檔案格式不正確，請上傳png, jpeg, jpg格式', 'warning')
          } else if (files[i].size > 10 * 1024 * 1024) {
            this.$swal('Warning', '上傳檔案大小超出上限，上限10MB。(檔案大小: ' + Math.round(files[i].size / 1024 / 1024).toString() + 'MB)', 'warning')
          } else {
            formData.append(`files[]`, files[i])
          }
        }
        const loader = this.$loading.show()
        this.$store.dispatch('uploadFiles', formData).then(res => {
          for (const x in res) {
            this.formData.images.push(res[x].url)
          }
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      }
    },
    imageCropper (e) {
      const files = e.target.files
      if (!files || !files.length > 0) {
        //
      } else if (!/\.(png|jpeg|jpg)$/.test(files[0].name.toLowerCase()) || (files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg')) {
        this.$swal('Warning', '上傳檔案格式不正確，請上傳png, jpeg, jpg格式', 'warning')
      } else if (files[0].size > 10 * 1024 * 1024) {
        this.$swal('Warning', '上傳檔案大小超出上限，上限10MB。(檔案大小: ' + Math.round(files[0].size / 1024 / 1024).toString() + 'MB)', 'warning')
      } else {
        const loader = this.$loading.show()
        this.generateImgB64(files, b64 => {
          this.$refs.uploadImage.value = null
          this.cropperModalData.img = b64
          this.cropperModalData.modal = true
          loader.hide()
        })
      }
    },
    cropImage (blob, name) {
      const formData = new FormData()
      formData.append('file', blob, name)
      this.uploadFile(formData, filePath => {
        this.cropperModalData.img = ''
        this.cropperModalData.modal = false
        this.formData.images.push(filePath)
      })
    },
    generateImgB64 (files, callback) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(files[0])
      fileReader.onload = (e) => {
        try {
          callback(e.target.result)
        } catch (e) {
          console.log(e)
          callback(false)
        }
      }
    },
    uploadFile (data, callback) {
      const loader = this.$loading.show()
      this.$store.dispatch('uploadFile', data).then(res => {
        callback(res[0].url)
        loader.hide()
      }).catch(e => {
        callback('')
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
  },
  beforeDestroy: function () {
  },
}
</script>
